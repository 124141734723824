import Keycloak from 'keycloak-js';

const keycloakSettings = {
    url: process.env.VUE_APP_BASE_KEYCLOAK_URL,
    realm: 'microservices-realm',
    clientId: 'admin-react-app'
};

const _kc = new Keycloak(keycloakSettings);

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const updateToken = (successCallback) =>
    _kc.updateToken().then(successCallback).catch(doLogin);

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const ownerName = () => {
    return _kc.tokenParsed.preferred_username;
};

const userRoles = () => {
    return _kc.tokenParsed.realm_access.roles;
};

function initKeycloak(authCallback) {
    let roles = [
        'ROLE_ADMIN',
        'ROLE_TELE2_AGENT',
        'ROLE_KCELL_AGENT',
    ];

    _kc.init({
        onLoad: 'login-required'
    }).then((auth) => {
        if (auth) {
            localStorage.setItem('token', getToken());
            localStorage.setItem('roles', JSON.stringify(_kc.tokenParsed.realm_access.roles));
            if (hasRole(roles)) {
                authCallback();
            } else {
                doLogout();
            }
        } else {
            console.log('Not Authenticated');
            doLogin();
        }
    });
}

const KeycloakService = () => {
    return {
        initKeycloak,
        doLogin,
        doLogout,
        getToken,
        updateToken,
        hasRole,
        ownerName,
        userRoles
    };
};

export default KeycloakService;
