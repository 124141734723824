export function setQueryParamsNew(page, pageSize, filtersArr) {
    let queryPart;
    let queryStr = '';
    let defaultStr = `page=${page}&size=${pageSize}`;

    for (let key in filtersArr) {
        switch (key) {
            case 'createdAt':
                filtersArr['createdAt'].forEach((el) => {
                    queryPart =
                        '&filter=createdAt' + '~~' + el.type + '~~' + el.filter;
                    queryStr = queryStr + queryPart;
                });
                break;
            case 'allBl':
                queryPart =
                    '&filter=allBl' +
                    '~~' +
                    'is' +
                    '~~' +
                    filtersArr['allBl']['type'];
                queryStr = queryStr + queryPart;
                break;
            case 'recurrentBl':
                queryPart =
                    '&filter=recurrentBl' +
                    '~~' +
                    'is' +
                    '~~' +
                    filtersArr['recurrentBl']['type'];
                queryStr = queryStr + queryPart;
                break;
            default:
                if (filtersArr[key]['conditions']) {
                    for (let keyInner in filtersArr[key]['conditions']) {
                        queryPart =
                            '&filter=' +
                            key +
                            '~~' +
                            filtersArr[key]['conditions'][keyInner].type +
                            '~~' +
                            filtersArr[key]['conditions'][keyInner].filter;
                        queryStr = queryStr + queryPart;
                    }
                } else {
                    queryPart =
                        '&filter=' +
                        key +
                        '~~' +
                        filtersArr[key].type +
                        '~~' +
                        filtersArr[key].filter;
                    queryStr = queryStr + queryPart;
                }
        }
        // if (key == 'createdAt') {
        // 	if (filtersArr['createdAt']) {
        // 		filtersArr['createdAt'].forEach((el) => {
        // 			queryPart = '&filter=createdAt' + '~~' + el.type + '~~' + el.filter;
        // 			queryStr = queryStr + queryPart;
        // 		})
        // 	}
        // } else {
        // 	// delete filtersArr['createdAt'];
        //
        // }
    }

    return defaultStr + queryStr;
}

export function setQueryParams(page, pageSize, filtersArr) {
    let queryPart;
    let queryStr = '';
    let defaultStr = `page=${page}&size=${pageSize}`;

    for (let key in filtersArr) {
        queryPart = '&' + key + '=' + filtersArr[key].filter;
        queryStr = queryStr + queryPart;
    }

    return defaultStr + queryStr;
}
