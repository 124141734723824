<script>
import request from "@/services/request";
import MainTableComponent from "@/components/MainTableComponent.vue";
import moment from 'moment/moment';
import 'moment-timezone';

export default{
    components: {MainTableComponent},
    data() {
        return {
            wallet: {
                moneyAmount: 0,
            },
            updatedAt: '',
            columnDefs: [
                {
                    headerName: 'Баланс за вчерашний день',
                    field: 'amountYesterday',
                    filter: false,
                    cellRenderer: (params) => {
                        if (params.value != 0) {
                            return params.value.toLocaleString() + ' ₸';
                        }
                        return params.value;
                    }
                },
                {
                    headerName: 'Баланс на сегодня',
                    field: 'amountToday',
                    filter: false,
                    cellRenderer: (params) => {
                        if (params.value != 0) {
                            return params.value.toLocaleString() + ' ₸';
                        }
                        return params.value;
                    }
                },
                {
                    headerName: 'Сумма списаний',
                    field: 'paidAmount',
                    filter: false,
                    cellRenderer: (params) => {
                        if (params.value != 0) {
                            return params.value.toLocaleString() + ' ₸';
                        }
                        return params.value;
                    }
                },
                {
                    headerName: 'Пополнение кошелька',
                    field: 'addBalance',
                    filter: false,
                    cellRenderer: (params) => {
                        if (params.value != 0) {
                            return params.value.toLocaleString() + ' ₸';
                        }
                        return params.value;
                    }
                },
                {
                    headerName: 'Сумма возвратов',
                    field: 'refundedAmount',
                    filter: false,
                    cellRenderer: (params) => {
                        if (params.value != 0) {
                            return params.value.toLocaleString() + ' ₸';
                        }
                        return params.value;
                    }
                },
                {
                    headerName: 'Дата',
                    field: 'scrapAt',
                    filter: false,
                    cellRenderer: (params) => {
                        return moment(params.value)
                            .tz('Asia/Aqtobe')
                            .format('YYYY-MM-DD, HH:mm');
                    }
                },
            ],
        }
    },
    methods: {
        getWalletData() {
            request.get(`agent-panel/wallets`)
                .then((res) => {
                    this.wallet = res.data.message;
                    this.updatedAt = new Date();
                }).catch((res) => {
                    console.log(res);
            })
        }
    },
    mounted() {
        this.getWalletData();
    }
}
</script>

<template>
    <div class="flex w-full flex-col items-start gap-2 h-[calc(100vh_-_32px)]">
        <div class="flex gap-1 flex-col bg-white text-left rounded-md p-4">
            <h1 class="text-xl font-bold">
                Текущий баланс кошелька:
                <span class="text-2xl">
                    {{ wallet.moneyAmount.toLocaleString() }}
                </span>
                ₸
            </h1>
            <div class="flex w-full gap-2 items-center">
                <button type="button" @click="getWalletData" class="p-1 hover:bg-gray-200 rounded-full transition-all">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                </button>
                <p class="text-left text-sm">Обновлено: {{ updatedAt }}</p>
            </div>
        </div>

        <div class="flex w-full h-full">
            <MainTableComponent
                :api-url="'agent-panel/daily-wallet-info'"
                :column-defs="columnDefs"
                :is-date-picker="true"
                :download-all-disable="true"/>
        </div>
    </div>
</template>