<script>
import AppLayout from "@/components/layouts/AppLayout.vue";

export default{
    components: {
        AppLayout,
    },
}
</script>

<template>
  <AppLayout/>

    <notifications
        width="400px"
        :duration="-1"
        group="errors"
        classes="errors-notify vue-notification"
        position="top right" />

    <notifications
        width="400px"
        group="success"
        classes="success-notify vue-notification"
        position="top right" />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.errors-notify {
    @apply bg-red-400 border-l-red-700 !important;
}
</style>