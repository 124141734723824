<script>
import AppSidebar from "@/components/layouts/AppSidebar.vue";

export default {
    components: {
        AppSidebar,
    }
}
</script>

<template>
    <div class="relative flex">
        <AppSidebar/>
        <div class="flex w-full ml-[335px] my-4 mr-4">
            <router-view/>
        </div>
    </div>

</template>

<style scoped>
.overflow-y-scroll::-webkit-scrollbar{
    width: 3px;
}
</style>