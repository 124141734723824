import './index.css';
import Vuex from 'vuex';
import './services/request';
import App from './App.vue';
import router from './router';
import { createApp } from 'vue';
import Notifications from '@kyvg/vue3-notification';
import KeycloakService from './services/KeycloakService';
import setupInterceptors from './services/setupInterceptors';

setupInterceptors();

const renderApp = () => {
    createApp(App)
        .use(Vuex)
        .use(router)
        .use(store)
        .use(Notifications)
        .use(KeycloakPlugin)
        .mount('#app');
};

let keycloak = KeycloakService();

keycloak.initKeycloak(renderApp);
const KeycloakPlugin = {
    install: (app) => {
        app.config.globalProperties.$keycloak = keycloak;
    }
};

const store = new Vuex.Store({
    state: {},
    mutations: {},
    getters: {}
});
