<script>
import { ref } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { setQueryParamsNew } from '@/services/utils';
import request from '@/services/request';
import * as XLSX from 'xlsx/xlsx.mjs';
import { csvToObj } from 'csv-to-js-parser';
import moment from 'moment';

export default {
    props: {
        apiUrl: String,
        columnDefs: Array,
        isPageable: Boolean,
        isDatePicker: Boolean,
        isPagination: Boolean,
        downloadPageDisable: Boolean,
        downloadAllDisable: Boolean,
        actionButton: Boolean,
        actionButtonText: String,
    },
    emits: [
        'rowDoubleClicked',
        'actionButton',
    ],
    components: {
        AgGridVue,
        VueDatePicker
    },
    setup() {
        const rowData = ref(null);

        return {
            rowData,
            currentPage: 0,
            totalPages: 0,
            pageSize: 20,
            totalElements: 0,
            date: null,
            filtersArr: {},
            isLoading: ref(true),
            defaultColDef: {
                sortable: true,
                flex: 1,
                minWidth: 200,
                filter: true,
                floatingFilter: false,
                resizable: true
            }
        };
    },
    methods: {
        onGridReady(params) {
            this.gridApi = params.api;
            this.outerDateFilter();

            params.api.addGlobalListener((type, e) => {
                if (type === 'rowDoubleClicked') {
                    this.$emit('rowDoubleClicked', e);
                }
                if (window.innerWidth < 1000) {
                    if (type === 'rowClicked') {
                        this.$emit('rowDoubleClicked', e);
                    }
                }
            });
        },
        getData() {
            this.isLoading = true;
            request
                .get(
                    `${this.apiUrl}?${setQueryParamsNew(
                        this.currentPage,
                        this.pageSize,
                        this.filtersArr
                    )}`
                )
                .then((res) => {
                    if (res.data.success) {
                        this.isLoading = false;
                        // this.rowData = res.data.message;
                        this.totalPages = res.data.message.totalPages;
                        this.totalElements = res.data.message.totalElements;

                        if (res.data.message.content) {
                            this.rowData = res.data.message.content;
                        } else {
                            this.rowData = res.data.message;
                        }
                    } else {
                        this.isLoading = false;

                        this.$notify({
                            group: 'errors',
                            title: 'Ошибка загрузки данных для таблицы!'
                        });
                    }
                })
                .catch((res) => {
                    this.isLoading = false;
                    console.log(res);
                });
        },
        changePageSize() {
            this.currentPage = 0;
            this.getData();
        },
        outerDateFilter() {
            if (this.isDatePicker) {
                if (this.date != null) {
                    if (this.date[0] && this.date[1]) {
                        this.filtersArr['createdAt'] = [
                            {
                                type: 'greaterThan',
                                filter: this.date[0] + 'T00:00:00'
                            },
                            {
                                type: 'lessThan',
                                filter: this.date[1] + 'T23:59:59'
                            }
                        ];
                    }
                } else {
                    delete this.filtersArr.createdAt;
                }
            } else {
                delete this.filtersArr.createdAt;
            }
            this.currentPage = 0;
            this.getData();
        },
        onFilterChanged(params) {
            let filterModel = params.api.getFilterModel();
            if (this.date != null) {
                if (this.date[0] && this.date[1]) {
                    filterModel['createdAt'] = [
                        {
                            type: 'greaterThan',
                            filter: this.date[0] + 'T00:00:00'
                        },
                        {
                            type: 'lessThan',
                            filter: this.date[1] + 'T23:59:59.999'
                        }
                    ];
                }
            } else {
                delete this.filtersArr.createdAt;
            }
            this.currentPage = 0;
            this.filtersArr = filterModel;
            this.getData();
        },
        importPage(event) {
            event.target.setAttribute('disabled', '');

            const csv = this.gridApi.getDataAsCsv();
            let csvData = csvToObj(csv);
            const workbook = XLSX.utils.book_new();
            const workSheet = XLSX.utils.json_to_sheet(csvData);
            XLSX.utils.book_append_sheet(workbook, workSheet, 'Table');
            XLSX.writeFileXLSX(
                workbook,
                'export_page_' + this.currentPage + '.xlsx'
            );

            setTimeout(() => {
                event.target.removeAttribute('disabled', '');
            }, 2000);
        },
        nextEl() {
            if (this.currentPage < this.totalPages - 1) {
                this.currentPage++;
                this.getData();
            }
        },
        prevEl() {
            if (!this.currentPage <= 0) {
                this.currentPage--;
                this.getData();
            }
        }
    },
    mounted() {
        if (this.isDatePicker) {
            this.date = [moment().format('Y-MM-DD'), moment().format('Y-MM-DD')];
        }
    }
};
</script>

<template>
    <div
        class="flex w-full rounded-xl overflow-hidden flex-col gap-2 justify-between">
        <div class="flex w-full">
            <form
                @submit.prevent="outerDateFilter()"
                class="flex w-full items-center justify-between gap-10">
                <div class="flex h-[30px] gap-10">
                    <select
                        @change="changePageSize()"
                        v-model="pageSize"
                        name="pageSize"
                        class="rounded-xl h-[30px] border border-gray-700 px-4 font-semibold">
                        <option value="" disabled selected="selected">
                            Размер страницы
                        </option>
                        <option value="20">20</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
<!--                        <option :value="totalElements">-->
<!--                            {{ totalElements }}-->
<!--                        </option>-->
                    </select>
                </div>
                <div v-if="isLoading" class="flex items-center w-full">
                    <div class="w-full">
                        <div class="h-1.5 w-full bg-pink-100 overflow-hidden">
                            <div
                                class="progress w-full h-full bg-pink-500 left-right"></div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="isDatePicker"
                    class="flex gap-4 justify-end min-w-[315px]">
                    <div class="flex h-[30px]">
                        <VueDatePicker
                            class="w-full h-full"
                            name="datePicker"
                            placeholder="Введите дату"
                            v-model="date"
                            model-type="yyyy-MM-dd"
                            range
                            :autoApply="true"
                            :enable-time-picker="false"
                            format="dd.MM.yyyy" />
                    </div>
                    <button
                        type="submit"
                        class="w-[30px] h-[30px] hover:bg-[#01dad3] text-white flex justify-center items-center bg-gray-700 rounded-xl transition-all">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                    </button>
                </div>
            </form>
        </div>
        <div v-if="actionButton" class="flex w-full px-5 pb-5">
            <button
                type="button"
                @click="$emit('actionButton')"
                class="flex items-center justify-center py-3 w-full bg-blue-500 hover:shadow-2xl transition-all text-white gap-1 max-w-[200px] rounded-lg">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                {{ actionButtonText }}
            </button>
        </div>
        <AgGridVue
            @grid-ready="onGridReady"
            style="width: 100%; height: 100%"
            class="ag-theme-alpine"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :onFilterChanged="onFilterChanged"
            :rowData="rowData">
        </AgGridVue>
        <div class="flex w-full items-center justify-between">
            <div
                class="flex h-[30px] rounded-xl w-[200px] justify-between overflow-hidden">
                <button
                    @click="importPage($event)"
                    :disabled="downloadPageDisable"
                    type="button"
                    class="w-full text-white bg-gray-700 hover:bg-[#01dad3] transition-all">
                    Экспорт
                </button>
            </div>
            <div
                class="flex h-[30px] rounded-xl overflow-hidden justify-between bg-gray-700 text-white w-[200px]">
                <div
                    @click="prevEl()"
                    class="flex h-full w-[50px] justify-center items-center hover:shadow-blue-500/40 hover:bg-[#01dad3] cursor-pointer transition-all">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                </div>
                <div class="flex h-full justify-center items-center">
                    {{ currentPage + 1 }} из {{ totalPages }}
                </div>
                <div
                    @click="nextEl()"
                    class="flex h-full w-[50px] justify-center items-center hover:shadow-blue-500/40 hover:bg-[#01dad3] cursor-pointer transition-all">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.progress {
    animation: progress 1s infinite linear;
}

.left-right {
    transform-origin: 0 50%;
}
@keyframes progress {
    0% {
        transform: translateX(0) scaleX(0);
    }
    40% {
        transform: translateX(0) scaleX(0.4);
    }
    100% {
        transform: translateX(100%) scaleX(0.5);
    }
}

button:disabled {
    @apply bg-gray-500;
    cursor: no-drop;
}

.ag-root-wrapper {
    border: none !important;
}

input[name='datePicker'] {
    @apply border border-gray-700 rounded-xl;
    height: 30px;
}

.ag-cell-value {
    text-align: left;
}
</style>

<style>
.dp__pointer {
    height: 30px;
}
</style>
