<script>
import keycloakService from "@/services/KeycloakService";

export default{
    data() {
        return {
            logoUrl: '/img/paygate_logo.svg',
        }
    },
    methods: {
        logout() {
            keycloakService().doLogout();
        },
        setLogoUrl() {
            const roles = JSON.parse(localStorage.getItem('roles'));

            if (roles.includes('ROLE_KCELL_AGENT')) {
                this.logoUrl = '/img/kcell_logo.webp';
            }

            if (roles.includes('ROLE_TELE2_AGENT')) {
                this.logoUrl = '/img/tele2_logo.png';
            }
        }
    },
    mounted() {
        this.setLogoUrl();
    }
}
</script>

<template>
    <div class="flex flex-col fixed top-0 left-0 w-full justify-between max-w-[300px] h-[calc(100vh_-_32px)] bg-gray-700 m-4 rounded-md overflow-hidden">
        <div class="flex w-full flex-col">
            <div class="flex w-full p-4 justify-center border-b-[1px] border-black">
                <div class="flex px-4 py-2 rounded-md bg-white">
                    <img :src="logoUrl" class="w-full max-w-[120px]" alt="">
                </div>
            </div>
            <div class="flex w-full flex-col gap-1 px-4 mt-4">
                <router-link class="flex gap-2 justify-start items-center px-4 py-3 text-white transition-all rounded-md" to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                    </svg>
                    Кошелёк
                </router-link>
                <router-link class="flex gap-2 justify-start items-center px-4 py-3 text-white transition-all rounded-md" to="/transactions">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                    </svg>
                    Транзакции
                </router-link>
            </div>
        </div>

        <div class="flex w-full px-4 py-4">
            <button @click="logout()" type="button" class="px-4 py-3 rounded-md flex gap-2 hover:bg-red-500 text-white transition-all">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
                </svg>
                <p>Logout</p>
            </button>
        </div>
    </div>
</template>